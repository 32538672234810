@import "media-queries";
@import "mixins";
@import "variables";
@import "fonts";

body {
  margin: 0;
}

.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  height: 100%;
  min-height: 100vh;
  height: 100%;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.form-check-input {
  &:checked {
    background-color: $color4Focus;
    border-color: $color4;
  }

  &:hover, &:focus {
    box-shadow: 0 0 0 0.25rem $color5;
  }
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg width='16' height='9' viewBox='0 0 16 9' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M7.7959 8.98242C8.10352 8.97363 8.38477 8.85938 8.61328 8.61328L15.293 1.77539C15.4863 1.58203 15.5918 1.33594 15.5918 1.0459C15.5918 0.46582 15.1348 0 14.5547 0C14.2734 0 14.001 0.114258 13.7988 0.316406L7.80469 6.47754L1.79297 0.316406C1.59082 0.123047 1.32715 0 1.03711 0C0.457031 0 0 0.46582 0 1.0459C0 1.33594 0.105469 1.58203 0.298828 1.77539L6.9873 8.61328C7.22461 8.85938 7.48828 8.98242 7.7959 8.98242Z' fill='%23C07FC5'/></svg>");

  background-repeat: no-repeat;
  background-position-x: calc(100% - 10px);
  background-position-y: 18px;
}

.dropdown-item.active, .dropdown-item:active {
  background-color: $color2;
}

.dropdown-item:hover, .dropdown-item:focus {
  background-color: $color7;
}