.page {
  font-family: 'Poppins';

  margin: 20px;
  display: flex;
  height: 100%;
  min-height: 100vh;
  width: calc(100% - 20px);


  .sidebar {
    width: 298px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-shrink: 0;
    box-shadow: none;

    .sidebar-container {
      box-sizing: border-box;
    }

    .logo {
      width: 191px;
      padding: 0;
      //height: 63px;
    }

    .calendar {
      margin: 20px 0;

      .left-arrow {
        display: inline-block;
        width: 28px;
        height: 40px;
        background: transparent url('../../../images/chevron.backward.svg') center center no-repeat;
        cursor: pointer;
      }

      .calendar-element {
        display: inline-block;
        width: calc(100% - 28px - 28px);
        height: 40px;
        overflow: hidden;

        input {
          width: calc(100% - 20px);
          margin: 0px 10px;
          height: 100%;
          color: #fff;
          background-color: #000;
          border: 0px solid #000;
          border-radius: 8px;
          padding: 0px 20px;
          font-weight: 500;
          font-size: 18px;
        }

        ::-webkit-calendar-picker-indicator {
          filter: invert(1);
        }
      }

      .right-arrow {
        display: inline-block;
        width: 28px;
        height: 40px;

        background: transparent url('../../../images/chevron.forward.svg') center center no-repeat;
        cursor: pointer;
      }
    }

    .calendar-events {
      margin-top: 20px;

      .calendar-event {
        margin-top: 10px;
        border-radius: 8px;
        background-color: #CEFFD6;
        padding: 7px 15px;

        &.full-day {
          background-color: #DCDCDC;
        }
      }
    }
  }

  .center-column {
    display: flex;
    flex-direction: column;
    width: calc(100% - 298px - 20px);
    padding-left: 20px;
    position: relative;

    h1 {
      width: 370px;
      height: 68px;

      font-style: normal;
      font-weight: 700;
      font-size: 45px;
      line-height: 68px;
      /* identical to box height */


      /* Main */

      color: #222222;
    }

    .disclaimer {
      margin-top: 50px;
      color: #777;
      font-size: 12px;
    }

    .exit-button {
      border: 2px solid #DCDCDC;
      border-radius: 6px;
      width: 53px;
      height: 53px;
      position: absolute;
      top: 20px;
      right: 0px;
      background: transparent url('../../../images/exit.svg') center center no-repeat;
      cursor: pointer;
    }
  }

}

.button {
  &.button_type_default   {
    background-color: #000;
  }
}