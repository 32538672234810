@import "../../styles/base/index.scss";

.login {
  display: flex;
  justify-content: center;
  min-height: 100vh;
  width: 400px;
  align-items: center;

  .login__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  & .button {
    margin: 0 auto;
    display: block;
  }
}
