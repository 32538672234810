@import "../../../styles/base/index.scss";

.guest-layout {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  
  .logo {
    padding-bottom: 40px;
  }
  
  h1 {
    margin-bottom: 80px;
  }

  .google-calendar-connect {
    padding-left: 60px;
    padding-top: 20px;
    padding-bottom: 20px;
    background: transparent url('../../../images/google-calendar-icon.svg') left center no-repeat;
    width: 400px;;
  }

  .garmin-connect {
    padding-left: 60px;
    padding-top: 20px;
    padding-bottom: 20px;
    background: transparent url('../../../images/garmin-connect.svg') left center no-repeat;
  }

  .step-counter {
    margin-top: 80px;
  }

  .button {
    color: #fff;
    background-color: #000;
    border-radius: 8px;
    height: 48px;
  }
}